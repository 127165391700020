import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import AWS from 'aws-sdk'
import DynamoDB from 'aws-sdk/clients/dynamodb'

AWS.config.update({ region: 'us-east-1' })

var dynamodb = new DynamoDB()

var params = {
  Key: {
    id: {
      S: '4DA8D6D7-7407-4449-9B7C-025267E74570',
    },
  },
  TableName: 'TelemetryBaseline-4l3liz36mvhohjid6satkgmksm-dev',
}
dynamodb.getItem(params, function (err, data) {
  if (err) console.log(err, err.stack) // an error occurred
  else console.log(data) // successful response
  /*
   data = {
    Item: {
     "AlbumTitle": {
       S: "Songs About Life"
      },
     "Artist": {
       S: "Acme Band"
      },
     "SongTitle": {
       S: "Happy Day"
      }
    }
   }
   */
})

class TagRoute extends Component {
  render () {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map(post => (
      <li key={post.node.fields.slug}>
        <Link to={post.node.fields.slug}>
          <h2 className='is-size-2'>{post.node.frontmatter.title}</h2>
        </Link>
      </li>
    ))
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`

    return (
      <Layout>
        <section className='section'>
          <Helmet title={`${tag} | ${title}`} />
          <div className='container content'>
            <div className='columns'>
              <div
                className='column is-10 is-offset-1'
                style={{ marginBottom: '6rem' }}
              >
                <h3 className='title is-size-4 is-bold-light'>{tagHeader}</h3>
                <ul className='taglist'>{postLinks}</ul>
                <p>
                  <Link to='/tags/'>Browse all tags</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
